import { Link } from "react-router-dom";

export default function Mentionslegales() {
    return (
        <main className="corps_page--interieur">
            <div>
                <h2>1 – Présentation du site.</h2>
                <p>En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie
                    numérique, il est précisé aux utilisateurs du site https://www.deborah-bude.fr l’identité des
                    différents
                    intervenants dans le cadre de sa réalisation et de son suivi :
                    <ul>
                        <li>Propriétaire : Déborah Bude</li>
                        <li>Créatrice : Déborah Bude</li>
                        <li>Responsable publication : Déborah Bude</li>
                        <li>Le responsable publication est une personne physique.</li>
                        <li>Webmaster : Déborah Bude – deborah.bude@gmail.com</li>
                        <li>Hébergeur : OVH, 2 rue Kellermann 59100 Roubaix</li>
                    </ul>
                </p>
            </div>

            <div>
                <h2>2 – Mentions légales</h2>
                <h3>2.1. Conditions d’utilisation</h3>
                <p>L’utilisation de ce site Internet vaut acceptation des conditions mentionnées ci-après. Ce site
                    internet,
                    autrement dit mon site, se réserve la possibilité d’engager des poursuites judiciaires en cas de
                    violation de ces conditions d’utilisation, notamment en cas de non-respect et d’utilisation non
                    autorisée de la charte graphique utiliser sur l’ensemble du site www.deborah-bude.fr ou en cas de
                    violation des droits liés à l’information, au graphisme, aux textes, vidéos, photos et images et
                    plus
                    généralement à l’ensemble du contenu posté sur le site web.</p>

                <h3>2.2. Logos et charte graphique</h3>
                <p>La charte graphique de ce site internet a été créée dans un but d’identification : afin que les
                    personnes
                    la voyant puissent reconnaître clairement et distinctement mes travaux… Il convient donc de
                    respecter
                    l’identité graphique qui a été mise en place. Toute utilisation non autorisée de la charte graphique
                    et
                    de son appellation constitue un usage illégal sanctionné pénalement par le droit français. Veuillez
                    nous
                    contacter avant toute utilisation afin que nous puissions vous donner notre accord.
                    Vous pouvez me contacter dans la section contact de mon portfolio de la page d’accueil.</p>

                <h3>2.3. Droits d’auteur</h3>
                <p>En application du Code français de la propriété intellectuelle et plus généralement des traités et
                    accords internationaux comportant des dispositions relatives à la protection des droits d’auteurs,
                    il
                    est interdit pour un usage autre que privé ou pédagogique de reproduire, vendre, diffuser, modifier,
                    publier intégralement ou partiellement, sous quelque forme que ce soit les données, la présentation
                    ou
                    l’organisation du site ainsi que les œuvres protégées par les droits d’auteur qui y figurent, sans
                    autorisation écrite particulière et préalable, dont la demande doit être adressée à le responsable
                    de
                    publication.
                    Vous pouvez me contacter dans la section contact de mon portfolio de la page d’accueil.</p>

                <h3>2.4. Crédit photos</h3>
                <p>Toutes photos de travaux graphique, de programmation et autres présents ont été fait dans la cadre de
                    travaux scolaire ou professionnel, toute reproduction ou utilisation de mon travail sans mon accord
                    est
                    formellement interdite.</p>

                <h3>2.5. Liens externes</h3>
                <p>Ce site Internet peut comporter des liens vers d’autres sites qui ne sont pas sous contrôle
                    permanent.
                    Ces liens sont considérés comme utiles et pouvant enrichir l’information présente sur le site. Je
                    n’endosse aucune responsabilité relative à ces sites, notamment en ce qui concerne l’exactitude ou
                    la
                    fiabilité des informations, des données et des opinions qui y figurent.</p>

                <h3>2.6. Décharge de responsabilité</h3>
                <p>J’effectue les démarches nécessaires pour assurer la fiabilité des informations contenues dans ce
                    site.
                    Cependant, elle ne peut encourir une quelconque responsabilité du fait d’erreurs, d’omissions ou des
                    résultats qui pourraient être obtenus à la suite de l’usage des informations diffusées. De même,
                    elle
                    n’est
                    en aucun cas responsable de l’infection par des virus ou de tout autre problème informatique ayant
                    des
                    effets destructeurs.</p>
            </div>
        </main>
    );
}
