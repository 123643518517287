import { Link } from "react-router-dom";

export default function Error404() {
	return (
		<main className="corps_page--interieur error">
			<h1 className="title--error">404</h1>
			<h2>Oups! La page que vous demandez n'existe pas.</h2>
			<Link className="button" to="/">
				Retourner sur la page d’accueil
			</Link>
		</main>
	);
}
