import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/index";
import Error404 from "./pages/Error/404";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Projets from "./pages/Projets/index";
import MentionsLegales from "./pages/mentionslegales";
import {Provider} from "react-redux";
import {store} from "./redux";
import './App.css';
import Parcours from "./pages/Parcours";

function App() {
  return (
      <Provider store={store}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/projets/:id" element={<Projets />} />
            <Route path="/parcours" element={<Parcours />} />
            <Route path="/mentions-legales" element={<MentionsLegales />} />
          <Route path="/page-non-trouvee" element={<Error404 />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
      </Provider>
  );
}

export default App;
