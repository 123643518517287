import {React} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMugHot, faCalendar, faCode, faHandshake } from '@fortawesome/free-solid-svg-icons'

export default function ParcoursItem({props}) {
    let typeParcours
    switch (props.type) {
        case "work":
            typeParcours = <FontAwesomeIcon icon={faMugHot} />;
            break;

        case "association":
            typeParcours = <FontAwesomeIcon icon={faHandshake} />;
            break;

        case "study":
            typeParcours = <FontAwesomeIcon icon={faCode} />;
            break;
        default:
            break;
    }

    return (
        <li key={"p" + props.title} className="timeline__item">
            <div className="timeline__icon"></div>

            <div className="timeline__content">
                <h2 className="card__titre">{props.title}</h2>
                <div className="timeline__content--info">
                  <div className="timeline__content-info-title">
                      {typeParcours}
                      <p>{props.compagny}</p>
                  </div>
                  <div className="timeline__content-info-date">
                      <FontAwesomeIcon icon={faCalendar} />
                      <p>{props.date}</p>
                  </div>
                </div>
                {props.description && <p>{props.description}</p>}
                <ul className="content-skills">
                    {
                        props.skills && props.skills.map((skill, index)=> {
                            return <li key={"skill" + index}>{skill}</li>
                        })
                    }
                </ul>
            </div>
        </li>
    )
}