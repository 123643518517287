import React from "react";
import github from "../assets/social/github.svg";
import behance from "../assets/social/behance.svg";
import viadeo from "../assets/social/viadeo.svg";
import linkedin from "../assets/social/linkedin.svg";

export default function Network() {
    return (
        <ul className="network">
            <li className="network-items">
                <a href="https://www.github.com/deborah-bude" target="_blank" rel="noreferrer noopener"
                ><img src={github} alt="Icône Github"
                /></a>
            </li>
            <li className="network-items">
                <a href="https://www.behance.net/dborahbude" target="_blank" rel="noreferrer noopener"
                ><img src={behance} alt="Icône Behance"
                /></a>
            </li>
            <li className="network-items">
                <a href="https://viadeo.journaldunet.com/p/deborah-bude-8066494" target="_blank" rel="noreferrer noopener"
                ><img src={viadeo} alt="Icône Viadeo"
                /></a>
            </li>
            <li className="network-items">
                <a href="https://fr.linkedin.com/in/d%C3%A9borah-bude-74b59a177" target="_blank" rel="noreferrer noopener"
                ><img src={linkedin} alt="Icône Linkedin"
                /></a>
            </li>
        </ul>
    )
}