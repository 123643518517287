import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <footer className="pied-page">
            <div className="pied-page__container">
                <p>Copyright© 2023 deborah-bude | Tout droits réservés</p>
                <Link className="pied-page--link" to="mentions-legales">Mentions Légales</Link>
            </div>
        </footer>
    )
}