import { React } from "react";
import { useParams, Navigate } from "react-router-dom";
import projectsData from "../../projects-and-skills.json";

export default function Projets() {
	const params = useParams();
	const project = redirectionProject(params.id);

	return <main className="corps-page">{project}</main>;
}

function redirectionProject(idProject) {
	const actualProject = projectsData.realisations.find((p) => p.id === idProject);
	if (actualProject !== undefined) {
		return templateProject(actualProject);
	}
	return <Navigate to="/page-non-trouvee" replace={true} />;
}

function templateProject(project) {
	return (
		<main className="corps_page--interieur">
			<section>
				<h1 id="title">{project.title}</h1>
				<h2 id="project_cat">Mes réalisations | <span>{project.categorie}</span></h2>
			</section>
			<section className="intro">
				<div>
					<img
						id="project_image"
						src={require("../../assets/projects/" + project.img)}
						alt={project.alt_img}
					/>
				</div>
				<div>
					{project.details.map((detail, index) => {
						return <p key={"detail" + index} id="project_details">{detail}</p>
					})}
					<ul id="caracteristiques">
						{
							project.caracteristiques.map((caract, index) => {
								return <li key={"caract" + index}>{caract}</li>
							})
						}
					</ul>
				</div>
			</section>

			<section className="details">
				<h2>Vous voulez en savoir plus ?</h2>
				<div>
					{
						project.otherDetails.map((detail, index) => {
							return <p key={"odetail" + index} id="desc">{detail}</p>
						})
					}
					{project.totalityProject &&
						<a className='button' target='_blank' rel="noreferrer noopener" href={project.totalityProject}>Voir la totalité du projet</a>
					}
				</div>
				<div>
					<ul className="project_characteristics">
						<li key="projectDate">Date : <span id="date">{project.date}</span></li>
						<li key="projectLogiciel">Logiciels : <span id="logiciels">{project.logiciels}</span></li>
						<li key="projectDifficulty">Difficultés rencontrées :
							<ul id="difficultes">
								{
									project.difficultes.map((difficulte, index) => {
										return <li key={"difficulty" + index}>{difficulte}</li>
									})
								}
							</ul>
						</li>
					</ul>
					<a className="button" href={project.url}>Voir le projet</a>
				</div>
			</section>
		</main>
	);
}
