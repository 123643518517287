import {configureStore, createSlice} from "@reduxjs/toolkit";

const darkModeStorage = JSON.parse(localStorage.getItem("dark_mode"))

const darkModeSlice = createSlice(({
    name: 'Night Mode',
    initialState: darkModeStorage === null ? false : darkModeStorage,
    reducers: {
        updateNightMode: (state, action) => {
            localStorage.setItem("dark_mode", !state)
            return !state
        }
    },
}))

const accessibilitySlice = createSlice(({
    name: 'Accessibility',
    initialState: {
        animationStop: undefined,
        dys: undefined,
        partiallySighted: undefined,
        sizeFont: null,
        heightLine: null,
        spaceWord: null,
    },
    reducers: {
        editAccessibilityConfig: (state, action) => {
            console.log(action.payload)
            return ({
                animationStop: action.payload.animationStop === "on" ? true : false,
                dys: action.payload.dys === "on" ? true : false,
                partiallySighted: action.payload.partiallySighted === "on" ? true : false,
                sizeFont: action.payload.sizeFont || action.payload.sizeFont === "1" ? action.payload.sizeFont : null,
                heightLine: action.payload.heightLine || action.payload.heightLine === "1" ? action.payload.heightLine : null,
                spaceWord: action.payload.spaceWord || action.payload.spaceWord === "0" ? action.payload.spaceWord : null,
            })
        },
        resetAccessibilityConfig: (state, action) => {
            return this.initialState
        }
    },

}))

export const { updateNightMode } = darkModeSlice.actions;
export const { editAccessibilityConfig, resetAccessibilityConfig } = accessibilitySlice.actions;

export const store = configureStore({
    reducer: {
        darkMode: darkModeSlice.reducer,
        accessibility: accessibilitySlice.reducer,
    }
})