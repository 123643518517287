import {React} from "react";
import ParcoursItem from "../../components/ParcoursItem";


export default function Parcours() {
    const association1 = {
        type: "association",
        title: "Développeuse front-end Bénévole",
        compagny: "Les Dix Lunes",
        date: "Novembre 2022 - À suivre",
        description: "Présence sur les stands de convention et prise de parole avec le public afin de leur présenter l'association. Refonte du site internet avec une nouvelle charte graphique avec en amont la réalisation des maquettes.",
        skills: ["Wordpress", "Elementor", "CSS", "HTML", "Communication", "Web Design"]
    }

    const work1 = {
        type: "work",
        title: "Alternance Dévelopeuse web",
        compagny: "L'Agence",
        date: "Septembre 2021 - Septembre 2023",
        description: "Alternance dans une agence de communication avec une grande variété de projets réalisés sur Wordpress (DIVI)",
        skills: ["Wordpress", "Divi", "CSS", "HTML", "Formation à l'outil"]
    }

    const work2 = {
        type: "work",
        title: "Alternance Dévelopeuse web",
        compagny: "XPVR",
        date: "mai 2020 - Septembre 2021",
        description: "2 refontes du site internet afin de le remettre au gout du jour et pour avoir une charte graphique attrayante qui correspondent à l'image de la boîte",
        skills: ["Wordpress", "Divi", "CSS", "HTML", "Veille VR"],
        allWork: ["Stagiaire - 1 mois", "Salarié - 1 semaine", "Alternance - 1 an"]
    }

    const work3 = {
        type: "work",
        title: "Service Civique",
        compagny: "Ligne de l'enseignement - Dordogne",
        date: "Octobre 2017 - Octobre 2018",
        skills: ["Montage, réparation et maintenance d’ordinateur", "Cours d’informatique pour débutant", "Conception d’activités ludiques numériques destinées aux enfants"]
    }

    const study1 = {
        type: "study",
        title: "Diplôme développeur web (Alternance et distanciel)",
        compagny: "OpenClassroom",
        date: "Septembre 2021 - Septembre 2023",
        description: "Formation sur le métier de développeur front-end avec une prise en main de Redux et toolkit, incorporant même la conception d'une librairie NPM et le débeugage avec JEst",
        skills: ["HTML", "SCSS", "JS", "React", "Redux et toolkit", "Librairie NPM", "JEst", "site responsive"]
    }

    const study2 = {
        type: "study",
        title: "Licence pro Web Design sensoriel (Alternance)",
        compagny: "Facultés Lettres et Sciences humaines - Limoges",
        date: "Septembre 2020 - Septembre 2021",
        description: "Année d'étude centré sur le sensoriel dans les sites internet avec comme objectif que le design, la communication fasse appel au 5 sens et ai une signification afin de toucher l'utilisateur un maximum",
        skills: ["Infographie", "UI / UX Design", "HTML", "SCSS", "JS", "Développement Wordpress"]
    }

    const study3 = {
        type: "study",
        title: "DUT Métier du Multimédia et de l'internet",
        compagny: "IUT du Limousin - Limoges",
        date: "Septembre 2018 - Septembre 2020",
        description: "Année d'étude centré sur le sensoriel dans les sites internet avec comme objectif que le design, la communication fasse appel au 5 sens et ai une signification afin de toucher l'utilisateur un maximum",
        skills: ["Infographie", "Développement Front-End et Back-End", "Gestion de projet", "Réseaux informatique", "Communication digitale"]
    }

    return (<main className="corps_page--interieur">
        <h1 className="titre">Mon parcours</h1>
        <p>Durant mes 5 dernières années d'études, dont 3 ans en alternance, j'ai pu acquérir beaucoup de compétences qui me permette d'être un véritable couteau suisse.</p>
        <p>J'ai aussi pu expérimenter différentes méthodes de travail : en présentiel et en distanciel, qui ont été très formateur pour moi.</p>
        <ul className="timeline">
            <ParcoursItem props={association1}/>
            <ParcoursItem props={work1}/>
            <ParcoursItem props={study1}/>
            <ParcoursItem props={work2}/>
            <ParcoursItem props={study2}/>
            <ParcoursItem props={study3}/>
            <ParcoursItem props={work3}/>
        </ul>
    </main>);
}