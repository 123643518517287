import React from "react";

export default function SkillsResume(props) {
    return (
        <div className="competences__items">
            <div className="front">
                <img className="competences__image" src={require("../assets/" + props.skills.img)} alt={props.skills.title}/>
                    <p className="competences__title">{props.skills.title}</p>
                    <p className="competences__desc">{props.skills.description}</p>
            </div>
            <div className="back">
                <p>{props.skills.title}</p>
                <p>{props.skills.description}</p>
            </div>
        </div>
    )
}