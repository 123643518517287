import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import "../../index.css";
import SkillsResume from "../../components/SkillsResume";
import projetsAndSkills from "../../projects-and-skills.json";
import Network from "../../components/Network";
import {HashLink, NavHashLink} from "react-router-hash-link";
import imgPresentation from "../../assets/image_presentation.webp"
import GitHub from "../../assets/social/github.svg"
import Figma from "../../assets/social/figma.svg"
import FigmaColor from "../../assets/social/figma-color.svg"
import Photoshop from "../../assets/social/photoshop.svg"
import Illustrator from "../../assets/social/illustrator.svg"
import AffiDesign from "../../assets/social/affinity-design.svg"
import AffiPhoto from "../../assets/social/affinity-photo.svg"
import WebSite from "../../assets/social/website.svg"
import cv from "../../assets/deborah-bude-cv.pdf"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHtml5, faSass, faSquareJs, faReact, faCss3Alt } from '@fortawesome/free-brands-svg-icons'
import Modal from 'react-modal';
import {useDispatch} from "react-redux";
import {editAccessibilityConfig, resetAccessibilityConfig} from "../../redux";
import accesibilityBG from "../../assets/geometric-shape/popup-background-shape.svg"

export default function Home() {

	const allProjects = projetsAndSkills.realisations.map((project) => {
		let icon;
		if(project.projectLink) {
			switch (project.projectLink[0]) {
				case "github":
					icon = GitHub;
					break;

				case "figma":
					icon = Figma;
					break;

				case "site":
					icon = WebSite;
					break;
				default:
					break;
			}
		}

		return(
				<article key={project.id} className="realisations__items {filterClass}">
					<img className="realisations__image" src={require("../../assets/projects/" + project.img)} alt={project.alt_img}/>
						<div className="realisations__details">
							<h3 className="card__titre">{project.title}</h3>
							<p className="realisations__logiciel">{project.logiciels}</p>
							<p className="realisations__desc">{project.description}</p>
							{icon && <a className="realisations__git-icon" href={project.projectLink[1]} target="__blank"><img src={icon} alt="Voir le projet sur Github"/></a> }
							<Link to={"/projets/" + project.id} className="button">Voir le projet</Link>
						</div>
				</article>
		)
	});

	return (
		<>
			<section id="top" className="presentation">
				<div className="presentation_container">
					<div className="presentation_details">
						<h1>Déborah Bude Développeuse Front-End</h1>
						<h2>Passionnée par le développement web</h2>
						<p>Formation Développeuse Front-End - Openclassroom</p>
					</div>
					<Network/>
					<NavHashLink className="button__scroll" smooth to={'/#a-propos'} title="Descendre la page jusqu'à la section en dessous">
						<svg width="51" height="93" viewBox="0 0 51 93" fill="none">
							<title>Scroll à la section à propos</title>
							<path className="button__scroll-ball" d="M12.8628 12.6813C19.8687 5.6754 31.2194 5.6754 38.2253 12.6813C45.2312 19.6872 45.2311 31.0378 38.2252 38.0437C31.2193 45.0496 19.8687 45.0497 12.8628 38.0438C5.85686 31.0379 5.85686 19.6872 12.8628 12.6813Z" fill="#FFAF00" />
							<path d="M12.8568 54.4085C19.8627 47.4026 31.2134 47.4027 38.2193 54.4086C45.2252 61.4145 45.2252 72.7651 38.2193 79.771C31.2134 86.7769 19.8627 86.7769 12.8568 79.771C5.85092 72.7651 5.85092 61.4144 12.8568 54.4085Z" stroke="#010101" strokeWidth="2.29" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
					</NavHashLink>
				</div>
				<div className="geometric-element">
					<svg className="zigzag" width="540" height="92" viewBox="0 0 540 92" fill="none" xmlns="http://www.w3.org/2000/svg" >
						<title>Zigzag noir et jaune</title>
						<path id="black" d="M1 43.6316L68.1921 91L135.384 43.6316L202.6 91L269.792 43.6316L337.008 91L404.2 43.6316L471.416 91L538.632 43.6316" stroke="#010101" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
						<path id="yellow" d="M1 1L68.1921 48.3684L135.384 1L202.6 48.3684L269.792 1L337.008 48.3684L404.2 1L471.416 48.3684L538.632 1" stroke="#FFAF00" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
					</svg>
					<svg className="square" width="142" height="142" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg" >
						<title>Carré jaune et contour noir</title>
						<path className="sens-anti-horaire" d="M69.0625 1.93756L1.93755 69.0625C0.867483 70.1325 0.867444 71.8675 1.93751 72.9375L69.0625 140.062C70.1325 141.133 71.8675 141.133 72.9375 140.062L140.062 72.9375C141.133 71.8675 141.133 70.1325 140.062 69.0625L72.9375 1.93752C71.8675 0.867453 70.1325 0.867492 69.0625 1.93756Z"  stroke="#FFAF00" strokeWidth="2" strokeMiterlimit="10" />
						<path className="sens-horaire" d="M65.11 36.3285L36.3021 65.1364C33.0497 68.3888 33.0497 73.6621 36.3021 76.9145L65.11 105.722C68.3625 108.975 73.6357 108.975 76.8881 105.722L105.696 76.9145C108.948 73.6621 108.948 68.3889 105.696 65.1364L76.8881 36.3285C73.6357 33.0761 68.3624 33.0761 65.11 36.3285Z" stroke="#010101" strokeWidth="2" strokeMiterlimit="10" />
					</svg>
					<svg className="square-points" width="258" height="281" viewBox="0 0 258 281" fill="none" xmlns="http://www.w3.org/2000/svg" >
						<title>Carré jaune et carré de points</title>
						<path d="M251.917 155.584L136.905 40.5718C135.084 38.7512 132.133 38.7512 130.312 40.5718L15.3003 155.583C13.4797 157.404 13.4797 160.356 15.3003 162.176L130.312 277.188C132.133 279.009 135.084 279.009 136.905 277.188L251.917 162.176C253.737 160.356 253.737 157.404 251.917 155.584Z" stroke="#FFAF00" strokeWidth="1.77" strokeMiterlimit="10" />
						<g className="sens-horaire">
							<path d="M135.961 5.36659C137.443 5.36659 138.644 4.16524 138.644 2.6833C138.644 1.20136 137.443 0 135.961 0C134.479 0 133.277 1.20136 133.277 2.6833C133.277 4.16524 134.479 5.36659 135.961 5.36659Z" fill="#010101" />
							<path d="M167.701 34.4221C166.644 35.4791 164.936 35.4791 163.906 34.4221C162.849 33.365 162.849 31.6575 163.906 30.6275C164.963 29.5705 166.671 29.5705 167.701 30.6275C168.758 31.6846 168.758 33.3921 167.701 34.4221Z" fill="#010101" />
							<path d="M197.513 64.2365C196.456 65.2935 194.748 65.2935 193.718 64.2365C192.688 63.1794 192.661 61.4719 193.718 60.4419C194.775 59.3849 196.483 59.3849 197.513 60.4419C198.57 61.499 198.57 63.2065 197.513 64.2365Z" fill="#010101" />
							<path d="M227.356 94.078C226.298 95.135 224.591 95.135 223.561 94.078C222.531 93.0209 222.504 91.3134 223.561 90.2834C224.618 89.2264 226.326 89.2264 227.356 90.2834C228.413 91.3405 228.413 93.048 227.356 94.078Z" fill="#010101" />
							<path d="M255.271 124.678C256.753 124.678 257.955 123.477 257.955 121.995C257.955 120.513 256.753 119.312 255.271 119.312C253.789 119.312 252.588 120.513 252.588 121.995C252.588 123.477 253.789 124.678 255.271 124.678Z" fill="#010101" />
							<path d="M111.213 31.2238C110.156 32.2809 108.448 32.2809 107.418 31.2238C106.388 30.1668 106.361 28.4592 107.418 27.4293C108.475 26.3993 110.183 26.3722 111.213 27.4293C112.27 28.4863 112.27 30.1939 111.213 31.2238Z" fill="#010101" />
							<path d="M141.054 61.0653C139.997 62.1224 138.289 62.1224 137.259 61.0653C136.229 60.0082 136.202 58.3007 137.259 57.2707C138.316 56.2408 140.024 56.2137 141.054 57.2707C142.111 58.3278 142.111 60.0353 141.054 61.0653Z" fill="#010101" />
							<path d="M170.868 90.8797C169.811 91.9368 168.103 91.9368 167.073 90.8797C166.016 89.8227 166.016 88.1151 167.073 87.0852C168.13 86.0281 169.838 86.0281 170.868 87.0852C171.925 88.1422 171.925 89.8498 170.868 90.8797Z" fill="#010101" />
							<path d="M200.709 120.721C199.652 121.778 197.944 121.778 196.914 120.721C195.885 119.664 195.857 117.957 196.914 116.927C197.972 115.87 199.679 115.87 200.709 116.927C201.766 117.984 201.766 119.691 200.709 120.721Z" fill="#010101" />
							<path d="M228.628 151.322C230.11 151.322 231.311 150.12 231.311 148.638C231.311 147.156 230.11 145.955 228.628 145.955C227.146 145.955 225.944 147.156 225.944 148.638C225.944 150.12 227.146 151.322 228.628 151.322Z" fill="#010101" />
							<path d="M84.5698 57.8941C83.5127 58.9512 81.8052 58.9512 80.7752 57.8941C79.7182 56.8371 79.7182 55.1295 80.7752 54.0996C81.8323 53.0425 83.5398 53.0425 84.5698 54.0996C85.6268 55.1566 85.6268 56.8642 84.5698 57.8941Z" fill="#010101" />
							<path d="M114.384 87.7086C113.327 88.7656 111.619 88.7656 110.589 87.7086C109.559 86.6515 109.532 84.9439 110.589 83.914C111.646 82.884 113.354 82.8569 114.384 83.914C115.441 84.971 115.441 86.6786 114.384 87.7086Z" fill="#010101" />
							<path d="M144.225 117.55C143.168 118.607 141.46 118.607 140.43 117.55C139.373 116.493 139.373 114.785 140.43 113.755C141.488 112.698 143.195 112.698 144.225 113.755C145.282 114.813 145.282 116.52 144.225 117.55Z" fill="#010101" />
							<path d="M174.04 147.364C172.983 148.421 171.276 148.421 170.246 147.364C169.216 146.307 169.188 144.6 170.246 143.57C171.303 142.513 173.01 142.513 174.04 143.57C175.097 144.627 175.097 146.334 174.04 147.364Z" fill="#010101" />
							<path d="M203.88 177.206C202.823 178.263 201.115 178.263 200.085 177.206C199.055 176.149 199.028 174.441 200.085 173.411C201.142 172.381 202.85 172.354 203.88 173.411C204.937 174.468 204.937 176.176 203.88 177.206Z" fill="#010101" />
							<path d="M57.8998 84.5374C56.8428 85.5944 55.1352 85.5944 54.1053 84.5374C53.0482 83.4803 53.0482 81.7728 54.1053 80.7428C55.1623 79.6858 56.8699 79.6858 57.8998 80.7428C58.9569 81.7999 58.9569 83.5074 57.8998 84.5374Z" fill="#010101" />
							<path d="M87.7407 114.379C86.6837 115.436 84.9761 115.436 83.9461 114.379C82.8891 113.322 82.8891 111.614 83.9461 110.584C85.0032 109.554 86.7108 109.527 87.7407 110.584C88.7978 111.641 88.7978 113.349 87.7407 114.379Z" fill="#010101" />
							<path d="M117.555 144.193C116.498 145.25 114.791 145.25 113.761 144.193C112.704 143.136 112.704 141.429 113.761 140.399C114.818 139.342 116.525 139.342 117.555 140.399C118.612 141.456 118.612 143.163 117.555 144.193Z" fill="#010101" />
							<path d="M147.396 174.035C146.339 175.092 144.631 175.092 143.601 174.035C142.544 172.978 142.544 171.27 143.601 170.24C144.658 169.183 146.366 169.183 147.396 170.24C148.453 171.297 148.453 173.005 147.396 174.035Z" fill="#010101" />
							<path d="M177.21 203.849C176.153 204.906 174.446 204.906 173.416 203.849C172.359 202.792 172.359 201.085 173.416 200.055C174.473 198.998 176.18 198.998 177.21 200.055C178.267 201.112 178.267 202.819 177.21 203.849Z" fill="#010101" />
							<path d="M31.2573 111.208C30.2002 112.265 28.4927 112.265 27.4627 111.208C26.4057 110.151 26.4057 108.443 27.4627 107.413C28.5198 106.356 30.2273 106.356 31.2573 107.413C32.2872 108.47 32.3143 110.178 31.2573 111.208Z" fill="#010101" />
							<path d="M61.0717 141.022C60.0147 142.079 58.3071 142.079 57.2772 141.022C56.2201 139.965 56.2201 138.257 57.2772 137.228C58.3342 136.198 60.0418 136.17 61.0717 137.228C62.1017 138.285 62.1288 139.992 61.0717 141.022Z" fill="#010101" />
							<path d="M90.9125 170.864C89.8555 171.921 88.1479 171.921 87.118 170.864C86.0609 169.807 86.0609 168.099 87.118 167.069C88.175 166.012 89.8826 166.012 90.9125 167.069C91.9696 168.126 91.9696 169.834 90.9125 170.864Z" fill="#010101" />
							<path d="M120.727 200.678C119.67 201.735 117.962 201.735 116.932 200.678C115.875 199.621 115.875 197.913 116.932 196.883C117.989 195.826 119.697 195.826 120.727 196.883C121.757 197.941 121.784 199.648 120.727 200.678Z" fill="#010101" />
							<path d="M150.568 230.52C149.511 231.577 147.803 231.577 146.773 230.52C145.716 229.462 145.716 227.755 146.773 226.725C147.83 225.668 149.538 225.668 150.568 226.725C151.625 227.782 151.625 229.49 150.568 230.52Z" fill="#010101" />
							<path d="M4.58734 137.851C3.53028 138.908 1.82274 138.908 0.792792 137.851C-0.264264 136.794 -0.264264 135.086 0.792792 134.056C1.84985 132.999 3.55738 132.999 4.58734 134.056C5.61729 135.113 5.64439 136.821 4.58734 137.851Z"  fill="#010101" />
							<path d="M34.4282 167.692C33.3711 168.75 31.6636 168.75 30.6336 167.692C29.5766 166.635 29.5766 164.928 30.6336 163.898C31.6907 162.868 33.3982 162.841 34.4282 163.898C35.4581 164.955 35.4852 166.663 34.4282 167.692Z"  fill="#010101" />
							<path d="M64.2426 197.507C63.1856 198.564 61.478 198.564 60.4481 197.507C59.391 196.45 59.391 194.742 60.4481 193.712C61.5051 192.655 63.2127 192.655 64.2426 193.712C65.2997 194.769 65.2997 196.477 64.2426 197.507Z"  fill="#010101" />
							<path d="M94.0834 227.348C93.0264 228.405 91.3188 228.405 90.2889 227.348C89.2318 226.291 89.2318 224.584 90.2889 223.554C91.3459 222.497 93.0535 222.497 94.0834 223.554C95.1134 224.611 95.1405 226.318 94.0834 227.348Z"  fill="#010101" />
							<path d="M123.898 257.163C122.841 258.22 121.133 258.22 120.103 257.163C119.046 256.106 119.046 254.398 120.103 253.368C121.16 252.311 122.868 252.311 123.898 253.368C124.928 254.425 124.955 256.133 123.898 257.163Z"  fill="#010101" />
						</g>
					</svg>
					<svg className="two-circles-traits" width="392" height="529" viewBox="0 0 392 529" fill="none" xmlns="http://www.w3.org/2000/svg" >
						<title>Deux demis cercles avec un ensemble de traits</title>
						<path id="half-circle-1" d="M256.895 55.735C331.039 129.197 331.176 248.402 256.895 322L-11.8281 55.735C62.3165 -17.7269 182.75 -17.7269 256.895 55.735Z" fill="#FFAF00" />
						<path id="half-circle-2" d="M-18.3403 329.265C-92.4849 255.803 -92.6215 136.598 -18.3403 63L250.383 329.265C176.238 402.727 55.8042 402.727 -18.3403 329.265Z" stroke="#FFAF00" strokeWidth="1.77" strokeMiterlimit="10" />
						<path d="M103.817 447.575L119.879 463.637" stroke="#010101" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M48.6719 392.402L78.1948 421.952" stroke="#010101" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
						<path
							d="M98.7266 395.978L209.938 507.19"
							stroke="#010101"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M178.654 429.428L276.513 527.287"
							stroke="#FFAF00"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M30.9863 281.786L135.346 386.119"
							stroke="#FFAF00"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M112.811 317.106L285.533 489.828"
							stroke="#010101"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M41.3867 245.682L77.9518 282.247"
							stroke="#010101"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M255.413 413.231L296.935 454.753"
							stroke="#010101"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M0 157.817L211.752 369.57"
							stroke="#010101"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M169.852 281.191L376.701 488.04"
							stroke="#FFAF00"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M78.4648 189.805L126.541 237.881"
							stroke="#FFAF00"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M209.992 274.853L343.631 408.464"
							stroke="#010101"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M73.5635 138.424L172.316 237.177"
							stroke="#010101"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M320.2 338.584L389.999 408.383"
							stroke="#FFAF00"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M133.91 152.292L269.499 287.881" stroke="#FFAF00" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
						<path d="M252.596 224.501L346.879 318.785" stroke="#010101" strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M145.097 117L215.627 187.503"
							stroke="#010101"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M336.398 261.825L373.072 298.498"
							stroke="#FFAF00"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M201.515 126.94L294.498 219.897"
							stroke="#FFAF00"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M317.331 196.278L339.866 218.813"
							stroke="#010101"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M277.759 156.707L293.306 172.254"
							stroke="#010101"
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<svg
						className="two-half-circle"
						width="184"
						height="185"
						viewBox="0 0 184 185"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<title>Deux demis cercles</title>
						<path
							id="shadow"
							d="M119.172 183.994C112.152 183.994 105.133 181.311 99.7966 175.975C89.0957 165.274 89.0957 147.896 99.7966 137.195C114.407 122.585 114.407 98.8145 99.7966 84.2042C85.1863 69.5939 61.416 69.5939 46.8058 84.2042C36.1049 94.9051 18.7266 94.9051 8.02567 84.2042C-2.67522 73.5033 -2.67522 56.1251 8.02567 45.4242C44.0092 9.44071 102.593 9.44071 138.576 45.4242C174.56 81.4077 174.56 139.992 138.576 175.975C133.212 181.34 126.192 184.022 119.201 184.022L119.172 183.994Z"
							fill="#FFAF00"
						/>
						<path
							d="M135.608 167.557C128.588 167.557 121.568 164.875 116.232 159.538C105.531 148.838 105.531 131.459 116.232 120.758C130.842 106.148 130.842 82.3779 116.232 67.7676C101.622 53.1574 77.8516 53.1574 63.2413 67.7676C52.5404 78.4685 35.1621 78.4685 24.4612 67.7676C13.7603 57.0668 13.7603 39.6885 24.4612 28.9876C60.4447 -6.99588 119.029 -6.99588 155.012 28.9876C190.996 64.9711 190.996 123.555 155.012 159.538C149.647 164.903 142.628 167.586 135.608 167.586V167.557Z"
							stroke="#010101"
							strokeWidth="2.56"
							strokeMiterlimit="10"
						/>
					</svg>
					<svg className="double-circle" width="178" height="178" viewBox="0 0 178 178" fill="none" xmlns="http://www.w3.org/2000/svg">
						<title>Double cercle noir et jaune</title>
						<path id="circle-rotation" d="M177.5 57.1524C177.5 88.6534 151.849 114.305 120.348 114.305C88.8466 114.305 63.1953 88.6534 63.1953 57.1524C63.1953 25.6513 88.8466 3.05889e-07 120.348 6.81535e-07C151.849 1.05718e-06 177.5 25.6513 177.5 57.1524ZM90.0906 57.1524C90.0906 73.8274 103.673 87.4095 120.348 87.4095C137.023 87.4095 150.605 73.8274 150.605 57.1524C150.605 40.4773 137.023 26.8952 120.348 26.8952C103.673 26.8952 90.0906 40.4773 90.0906 57.1524Z" fill="#010101" />
						<path d="M115.305 119.348C115.305 150.849 89.6534 176.5 58.1524 176.5C26.6513 176.5 1 150.849 1 119.348C1 87.8466 26.6513 62.1952 58.1524 62.1952C89.6534 62.1952 115.305 87.8466 115.305 119.348ZM27.8952 119.348C27.8952 136.023 41.4773 149.605 58.1524 149.605C74.8274 149.605 88.4095 136.023 88.4095 119.348C88.4095 102.673 74.8274 89.0905 58.1524 89.0905C41.4773 89.0905 27.8952 102.673 27.8952 119.348Z"  stroke="#FFAF00" strokeWidth="2" strokeMiterlimit="10"/>
					</svg>
				</div>
			</section>

			<main className="corps_page">
				<section id="a-propos">
					<div className="a-propos__img">
						<img src={imgPresentation} alt="Déborah Bude présentation"/>
					</div>
					<div className="a-propos__desc">
						<h2 className="titre">À propos</h2>
						<p>Passionnée par le développement informatique, la réalité virtuelle et la 3D, je me forme tous les jours afin d'améliorer mes compétences en développement web.</p>
						<p>
							Aujourd'hui, après cinq ans d'études dans le multimédia, le webdesign et le développement
							front-end, dont trois ans en alternance, je cherche un travail en développement front-end
							dans une entreprise en Haute-Vienne.
						</p>
						<p>
							En dehors de ma vie professionnelle, je m'adonne à d'autres passions telles que le cosplay,
							les jeux vidéos ou encore les mangas.
						</p>
						<a className="button" href={cv} target="_blank">Voir mon CV</a>
						<Link className="button--second" to="/parcours">Découvrir mon parcours</Link>
						<svg
							className="a-propos-cross"
							width="78"
							height="93"
							viewBox="0 0 78 93"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<title>Croix noire et jaune</title>
							<path
								d="M59.9164 17.0436L38.7558 38.2042L17.5952 17.0436L1 33.6388L22.1606 54.7994L1 75.96L17.5952 92.5552L38.7558 71.3946L59.9164 92.5552L76.5116 75.96L55.351 54.7994L76.5116 33.6388L59.9164 17.0436Z"
								fill="#010101"
							/>
							<path
								id="stroke"
								d="M59.9282 1.00003L38.7676 22.1606L17.6069 1.00003L1 17.5952L22.1606 38.7558L1 59.9164L17.6069 76.5234L38.7676 55.3628L59.9282 76.5234L76.5233 59.9164L55.3627 38.7558L76.5233 17.5952L59.9282 1.00003Z"
								stroke="#FFAF00"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<svg
							className="a-propos-square"
							width="190"
							height="190"
							viewBox="0 0 190 190"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<title>Double carré jaune contour</title>
							<path
								className="sens-horaire"
								d="M109.63 18.9145L19.8495 76.0023C18.4311 76.9042 18.0124 78.7852 18.9143 80.2037L76.0021 169.984C76.9041 171.402 78.7851 171.821 80.2035 170.919L169.984 113.831C171.402 112.929 171.821 111.048 170.919 109.63L113.831 19.8498C112.929 18.4313 111.048 18.0126 109.63 18.9145Z"
								stroke="#FFAF00"
								strokeWidth="2.37"
								strokeMiterlimit="10"
							/>
							<path
								className="sens-anti-horaire"
								d="M103.318 51.4699L52.0074 84.0965C51.1911 84.6156 50.9501 85.6982 51.4692 86.5146L84.0958 137.825C84.6149 138.642 85.6975 138.883 86.5139 138.364L137.825 105.737C138.641 105.218 138.882 104.135 138.363 103.319L105.736 52.0081C105.217 51.1918 104.135 50.9508 103.318 51.4699Z"
								stroke="#FFAF00"
								strokeWidth="2.37"
								strokeMiterlimit="10"
							/>
						</svg>
					</div>
				</section>
				<section id="competences">
					<div className="competences__details">
						<svg
							className="competences-cercle"
							width="157"
							height="158"
							viewBox="0 0 157 158"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<title>Cercle compétences jaune et noire</title>
							<path
								id="circle-1"
								d="M141.635 85.599C157.395 101.359 157.395 126.921 141.635 142.681C125.875 158.441 100.314 158.441 84.5537 142.681"
								stroke="#FFAF00"
								strokeWidth="6"
								strokeMiterlimit="10"
							/>
							<path
								id="circle-2"
								d="M142.181 86.1221C157.941 101.882 157.941 127.444 142.181 143.204C126.421 158.964 100.86 158.964 85.0996 143.204"
								stroke="#010101"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								id="circle-3"
								d="M81.7331 120.394C60.4242 120.394 43.0723 103.042 43.0723 81.7331C43.0723 60.4242 60.4242 43.0723 81.7331 43.0723C103.042 43.0723 120.394 60.4242 120.394 81.7331C120.394 103.042 103.042 120.394 81.7331 120.394ZM81.7331 61.2656C70.4533 61.2656 61.2656 70.4533 61.2656 81.7331C61.2656 93.013 70.4533 102.201 81.7331 102.201C93.013 102.201 102.201 93.013 102.201 81.7331C102.201 70.4533 93.013 61.2656 81.7331 61.2656Z"
								fill="#010101"
							/>
							<path
								id="circle-4"
								d="M39.6609 78.3218C18.3519 78.3218 1 60.9699 1 39.661C1 18.352 18.3519 1.00012 39.6609 1.00012C60.9698 1.00012 78.3217 18.352 78.3217 39.661C78.3217 60.9699 60.9698 78.3218 39.6609 78.3218ZM39.6609 19.1935C28.381 19.1935 19.1933 28.3811 19.1933 39.661C19.1933 50.9409 28.381 60.1285 39.6609 60.1285C50.9407 60.1285 60.1284 50.9409 60.1284 39.661C60.1284 28.3811 50.9407 19.1935 39.6609 19.1935Z"
								stroke="#FFAF00"
								strokeWidth="2"
								strokeMiterlimit="10"
							/>
						</svg>
						<h2 className="titre">Mes compétences</h2>
						<p>
							J'ai acquis diverses compétences au cours de mes 5 années formations, notamment en
							développement
							front-end et back-end, en webdesign, en UX et bien d'autres encore. Grâce à cette
							polyvalence, j'ai une bonne compréhension du travail des autres corps de métier et je
							suis en mesure de travailler en harmonie avec eux.
						</p>

						<p>
							En outre, j'ai également appris beaucoup de choses de manière autodidacte, ce qui m'a
							permis de me spécialiser dans le développement front-end. Aujourd'hui, c'est devenu mon
							métier principal.
						</p>
					</div>

					<div id="competences__li">
						<div className="competences__cards col1">
							<SkillsResume skills={projetsAndSkills.skills[0]}/>
							<SkillsResume skills={projetsAndSkills.skills[1]}/>
						</div>
						<div className="competences__cards col2">
							<SkillsResume skills={projetsAndSkills.skills[2]}/>
							<SkillsResume skills={projetsAndSkills.skills[3]}/>
						</div>
					</div>

					<div className="competences__stack">
						<h3 className="card__titre">Mon stack technique</h3>
						<div className="competences__stack-code">
							<p className="competences__stack--sous-titre">Programmation</p>
							<ul>
								<li><FontAwesomeIcon icon={faHtml5} /> HTML</li>
								<li><FontAwesomeIcon icon={faCss3Alt} /> CSS</li>
								<li><FontAwesomeIcon icon={faSass} /> Sass</li>
								<li><FontAwesomeIcon icon={faSquareJs} /> Javascript</li>
								<li><FontAwesomeIcon icon={faReact} /> React et Redux</li>
							</ul>
						</div>
						<div className="competences__stack-design">
							<p className="competences__stack--sous-titre">Design</p>
							<ul>
								<li><img src={FigmaColor} alt="Figma"/> Figma</li>
								<li><img src={Photoshop} alt="Photoshop"/> Photoshop</li>
								<li><img src={AffiPhoto} alt="Affinity Photo"/>Affinity Photo</li>
								<li><img src={Illustrator} alt="Illustrator"/>Illustrator</li>
								<li><img src={AffiDesign} alt="Affinity Design"/>Affinity Design</li>
							</ul>
						</div>
					</div>
				</section>

				<section id="realisations">
					<h2 className="titre">Mes réalisations</h2>
					<div className="realisations__div">{allProjects}</div>
					<p>Vous voulez en savoir plus ? Contactez-moi tout de suite et nous pourrons en discuter !</p>
					<HashLink className="button" to="#contact" smooth>Me contacter</HashLink>
				</section>
				<div id="contact">
					<section className="contact">
						<div className="contact__desc">
							<h2 className="titre">Contact</h2>
							<p>
								Vous appréciez mon travail et mon profil ? Je vous propose de me contacter dès maintenant !
							</p>
							<p>
								En attendant, vous pouvez également me suivre sur les réseaux sociaux pour en savoir plus sur mon travail et mes projets en cours.
							</p>
							<Network/>
							<svg
								className="contact-fleche"
								width="173"
								height="196"
								viewBox="0 0 173 196"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<title>Flèche envoie formulaire</title>
								<path
									d="M3.25488 79.3185L170.975 36.9834L74.7449 195.017L3.25488 79.3185Z"
									fill="#FFAF00"
								/>
								<path
									className="anim"
									d="M1 30.5996L171.42 1L63.5339 151.348L1 30.5996Z"
									stroke="#010101"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									className="anim"
									d="M171.42 1C168.308 2.36565 60.8027 65.8524 60.8027 65.8524L63.5342 151.348L171.42 1Z"
									stroke="#010101"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									className="anim"
									d="M1 30.5996L60.8025 65.8206L171.42 1L1 30.5996Z"
									stroke="#010101"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<form className="contact__form" action="https://public.herotofu.com/v1/053d9850-66c4-11ee-94f4-efb6cbd494ee" method="post"
							  acceptCharset="UTF-8">
							<div className="contact__form-items half-width">
								<label htmlFor="name">Nom</label>
								<input name="Nom" id="name" type="text" required/>
							</div>
							<div className="contact__form-items half-width">
								<label htmlFor="firstname">Ton prénom</label>
								<input name="Prénom" id="firstname" type="text" required/>
							</div>
							<div className="contact__form-items half-width">
								<label htmlFor="email">Ton e-mail</label>
								<input name="Email" id="email" type="email" required/>
							</div>
							<div className="contact__form-items half-width">
								<label htmlFor="subject">Sujet</label>
								<input name="Sujet" id="subject" type="text" required/>
							</div>
							<div className="contact__form-items">
								<label htmlFor="email">Tu as une question à me poser ? Ou un projet à me partager ? Dis moi tout ici !</label>
								<textarea name="Email" id="email" required />
							</div>
							<div>
								<button className="button" type="submit">Envoyer</button>
								<div
									style={{
										textIndent: "-99999px",
										whiteSpace: "nowrap",
										overflow: "hidden",
										position: "absolute"
									}}
									aria-hidden="true">
									<input type="text" name="_gotcha" tabIndex="-1" autoComplete="off"/>
								</div>
							</div>
						</form>
					</section>
				</div>
			</main>
		</>
	);
}
